import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AccountInfo } from '@azure/msal-common/src/account/AccountInfo';
import { User } from 'aimmo-core2/app/shared/models/user.model';
import { Workspace } from 'aimmo-core2/app/shared/models/workspace.model';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class StoreService {
  public currentUser = signal<User | null>(null);
  public workspaces = signal<Workspace[] | null>(null);

  public setCurrentUser(user: User): void {
    this.currentUser.set(user ?? null);
  }

  public setWorkspaces(workspaces: Workspace[]): void {
    this.workspaces.set(workspaces ?? null);
  }

  public getCurrentUser$(): Observable<User | AccountInfo | null> {
    return toObservable(this.currentUser);
  }
}
